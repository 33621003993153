import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Container from "../../components/container/container";
import Navigation from "../../components/navigation/navigation.de";
import Summaries from "../../components/blog/summary/summaries.de";
import Legal from "../../components/legal/legal.de";
import SEO from "../../components/blog/seo/seo";
export const _frontmatter = {
  "lang": "de",
  "title": "Blog Sport Ernährung Abnehmen",
  "summary": "Lerne alles über's Abnehmen, Muskelaufbau und gesunde Ernährung! Einfache Schritt für Schritt Anleitungen zu einem gesunden und schönen Körper. Fange JETZT an!",
  "keywords": "abnehmen, gesunde ernährung, gesund ernähren, ernährungsplan, ernährung, nährstoffe, essen ohne kohlenhydrate, ernährungsplan abnehmen, low carb diät, ausgewogene ernährung, ernährungsplan muskelaufbau, ernährungsweise, essen nach sport, muskelaufbau, trainingsplan muskelaufbau, ernährungsplan muskelaufbau, muskelaufbau trainingsplan, muskelmasse, proteinzufuhr muskelaufbau, kalorienrechner, kalorienbedarf berechnen, kalorienbedarf ausrechnen, kalorienbedarfsrechner, kalorienbedarf, kalorienverbrauch berechnen"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = function Layout({
  children
}) {
  return <>{children}</>;
};
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} mdxType="SEO" />
    <Container mdxType="Container">
  <Navigation mdxType="Navigation" />
  <Summaries mdxType="Summaries" />
  <Legal mdxType="Legal" />
    </Container>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      